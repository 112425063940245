@tailwind base;
@tailwind components;
@tailwind utilities;

/* @link https://utopia.fyi/type/calculator?c=320,16,1.25,1240,18,1.25,6,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
  --step--2: clamp(0.64rem, 0.6122rem + 0.1391vi, 0.72rem);
  --step--1: clamp(0.8rem, 0.7652rem + 0.1739vi, 0.9rem);
  --step-0: clamp(1rem, 0.9565rem + 0.2174vi, 1.125rem);
  --step-1: clamp(1.25rem, 1.1957rem + 0.2717vi, 1.4063rem);
  --step-2: clamp(1.5625rem, 1.4946rem + 0.3397vi, 1.7578rem);
  --step-3: clamp(1.9531rem, 1.8682rem + 0.4246vi, 2.1973rem);
  --step-4: clamp(2.4414rem, 2.3353rem + 0.5307vi, 2.7466rem);
  --step-5: clamp(3.0518rem, 2.9191rem + 0.6634vi, 3.4332rem);
  --step-6: clamp(3.8147rem, 3.6488rem + 0.8293vi, 4.2915rem);
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Outfit Variable", sans-serif;
  cursor: default;
  color-scheme: light;
  @apply overflow-x-clip dark:bg-black dark:text-white;
  color-scheme: light dark;
}

@layer base {
  h1 {
    @apply text-3xl md:text-5xl font-bold !leading-snug lg:!leading-tight;
  }

  h2 {
    @apply text-2xl font-bold;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  h4 {
    @apply text-lg font-bold;
  }

  p {
    @apply text-base;
  }

  section:has(div) {
    @apply flex w-full flex-col md:flex-row relative py-20 overflow-x-clip;
  }

  section > div {
    @apply w-full container flex-col justify-center px-4 md:px-8 flex h-full gap-y-10 max-w-4xl lg:max-w-5xl;
  }

  section:has(> .divider) {
    @apply pb-0 relative;
  }

  .second-carousel-item-in-view {
    scale: 0.95;
  }
  .third-carousel-item-in-view {
    scale: 0.9;
  }
}

@layer utilities {
  .mockup-browser .mockup-browser-toolbar {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    /* @apply my-2; */
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding-right: 1.4em;
  }

  .mockup-browser .mockup-browser-toolbar:before {
    content: "";
    margin-right: 4.8rem;
    display: inline-block;
    aspect-ratio: 1/1;
    height: 0.75rem;
    @apply mix-blend-exclusion;
    /* height: 0.65rem; */
    border-radius: 9999px;
    box-shadow: 1.4em 0, 2.8em 0, 4.2em 0;
    /* box-shadow: 1.213em 0, 2.426em 0, 3.64em 0; */
  }

  .mockup-browser .mockup-browser-toolbar .input {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    height: 1.75rem;
    /* @apply h-6; */
    width: 24rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    --tw-bg-opacity: 1;
    /* background-color: var(--fallback-b2,oklch(var(--b2)/var(--tw-bg-opacity))); */
    /* padding-left: 2rem; */
    direction: ltr;
  }

  .smooth-shadow-light {
    box-shadow: 0px 2.5px 11px rgba(0, 0, 0, 0.036),
      0px 6.1px 19px rgba(0, 0, 0, 0.048),
      0px 11.5px 25.9px rgba(0, 0, 0, 0.057),
      0px 20.5px 33.7px rgba(0, 0, 0, 0.068),
      0px 38.4px 45.9px rgba(0, 0, 0, 0.088), 
      0px 92px 80px rgba(0, 0, 0, 0.15);
  }

  .mobile-smooth-shadow-light {
    box-shadow: 0px 2.5px 11px rgba(0, 0, 0, 0.036),
      0px 6.1px 19px rgba(0, 0, 0, 0.048),
      0px 11.5px 25.9px rgba(0, 0, 0, 0.057),
      0px 20.5px 33.7px rgba(0, 0, 0, 0.068);
  }
  
  .smooth-shadow-dark {
    box-shadow: 0px 2.5px 11px rgba(255, 255, 255, 0.036),
      0px 6.1px 19px rgba(255, 255, 255, 0.048),
      0px 20.5px 33.7px rgba(255, 255, 255, 0.068),
      0px 92px 80px rgba(255, 255, 255, 0.11);
  }

  .mobile-smooth-shadow-dark {
    box-shadow: 0px 2.5px 11px rgba(255, 255, 255, 0.036),
      0px 6.1px 19px rgba(255, 255, 255, 0.048),
      0px 20.5px 33.7px rgba(255, 255, 255, 0.068),
      0px 92px 80px rgba(255, 255, 255, 0.11);
  }
}
