.cake-slice-container {
  position: relative;
  width: 200px; /* Diameter of the full circle */
  height: 200px;
  border-radius: 50%;
  background: transparent;
  /* clip-path: polygon(50% 50%, 100% 0, 100% 100%); */
  /* transform: rotate(-72deg); */
  overflow: hidden;
}

.cake-slice {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f06; /* Change to desired slice color */
  border-radius: 50%;
  /* clip-path: polygon(0 50%, 100% 0, 100% 100%); */
}

/* Rotate each slice accordingly */
.cake-slice-container:nth-child(1) .cake-slice { transform: rotate(72deg); }
.cake-slice-container:nth-child(2) .cake-slice { transform: rotate(144deg); }
.cake-slice-container:nth-child(3) .cake-slice { transform: rotate(216deg); }
.cake-slice-container:nth-child(4) .cake-slice { transform: rotate(288deg); }
.cake-slice-container:nth-child(5) .cake-slice { transform: rotate(360deg); }


@keyframes flyInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
    /* visibility: hidden; */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    /* visibility: visible; */
  }
}

.fly-in-from-bottom {
  animation: flyInFromBottom 0.5s forwards; /* Ensure 'forwards' is set here */
}


@keyframes flyOutToTop {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.fly-out-to-top {
  animation: flyOutToTop 0.5s forwards;
}

@keyframes flyInFromTop {
  0% {
    opacity: 0;
    transform: translateY(-50px);
    }
    10% {
    opacity: 0;
    }
    100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fly-in-from-top {
  /* opacity: 0; */
  animation: flyInFromTop 0.6s forwards;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes SlideOutToLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes SlideOutToRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

.animate-slide-in-from-right {
  animation: slideInFromRight 0.6s forwards;
}

.animate-slide-out-to-right {
  animation: SlideOutToRight 0.6s forwards;
}

.animate-slide-out-to-left {
  animation: SlideOutToLeft 0.6s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-slideInFromLeft {
  animation: slideInFromLeft 0.6s ease-out forwards;
}

.animate-fadeIn {
  animation: fadeIn 0.6s ease-out forwards;
}

.animate-growIn {
  animation: growIn 0.6s ease-out forwards;
}

@keyframes fadeInHalfOut {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    scale: 1;
  }
  100% {
    opacity: 0.6;
    scale: 0.8;
  }
}

.animate-fadeInHalfOut {
  animation: fadeInHalfOut 1.8s ease-out forwards;
}


.tab-content-enter {
  opacity: 0;
  transform: translateX(50%);
}
.tab-content-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.tab-content-exit {
  opacity: 1;
  transform: translateX(0);
}
.tab-content-exit-active {
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 300ms, transform 300ms;
}