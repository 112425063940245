/* Base state for all tab content, ensure it is always rendered in the layout */
[data-state="inactive"] div.tab-content, [aria-hidden="true"] div.tab-content {
  width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateX(-100%); /* Left for hidden */
  opacity: 0;
  display: none; /* Maintain hidden state as invisible */
}

/* Visible state, overriding hidden attribute */
[data-state="active"] div.tab-content, [aria-hidden="false"] div.tab-content {
  transform: translateX(0); /* Center the content when it is active */
  opacity: 1;
  display: block; /* Override hidden */
}