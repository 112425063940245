/* @-webkit-keyframes mask-play {
  from {
	-webkit-mask-position: 0% 0;
	mask-position: 0% 0;
  }
  to {
	-webkit-mask-position: 100% 0;
	mask-position: 100% 0;
  }
} */

@keyframes mask-play {
  from {
	-webkit-mask-position: 0% 0;
	mask-position: 0% 0;
  }
  to {
	-webkit-mask-position: 100% 0;
	mask-position: 100% 0;
  }
}

.sideblur-mask {
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  mask-repeat: no-repeat;
  mask-image: linear-gradient(to right, black 95%, transparent 99%);
  -webkit-mask-image: linear-gradient(to right, black 95%, transparent 99%);
}

.hide-mask {
	-webkit-animation: mask-play 0.3s forwards;
	animation: mask-play 0.3s forwards;
}

.show-mask {
  -webkit-animation: mask-play 0.3s reverse forwards;
  animation: mask-play 0.3s reverse forwards;
}