/* Navbar.css */

.nav-background {
  /* background-color: white; */
  z-index: 0;
  opacity: 1;
  height: 100%;

  transition: left 0.3s ease, width 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
  @apply  absolute rounded-md;
}